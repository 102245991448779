import React from "react";
import styled, { css } from "styled-components";
import ReactModal from "react-modal";

import {
  colors,
  sizes,
  animationCurves,
  responsiveTargets,
  containerWidths
} from "suited/constants/style-constants";
import { timeouts } from "suited/constants/interaction-constants";
import Paper from "../Paper";
import { Button } from "suited/components/shared/buttons/SuitedIconButton.style";
import { DetailPrimaryValue } from "suited/components/shared/typography/SuitedDetailPrimaryValue";

interface Props extends ReactModal.Props {
  className?: string;
}

// The below FunctionComponents comes from: https://github.com/styled-components/styled-components/issues/1494
const ReactModalAdapter: React.FunctionComponent<Props> = ({ className, ...props }: Props) => {
  return (
    <ReactModal
      portalClassName={className}
      className={className}
      overlayClassName={className}
      {...props}
    />
  );
};

export const phonePadding = sizes.gridUnits.times3;
export const tabletPadding = sizes.gridUnits.times4;
export const desktopPadding = sizes.gridUnits.times6;
const scrollbarGap = sizes.gridUnits.times2;
export const fadePadding = sizes.gridUnits.divide2;
const iconButtonRowHeight = sizes.gridUnits.times5;
const footerHeight = sizes.gridUnits.times14;
export const footerTopPadding = sizes.gridUnits.times2;

export const StyledModal = styled(ReactModalAdapter)<{ maxWidth?: number }>`
  ${({ maxWidth, closeTimeoutMS }) => {
    return css`
      &.ReactModal__Overlay {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1 1 auto;
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background-color: rgba(255, 255, 255, 0.9);
        background-color: ${colors.background.scrim};
        opacity: 0;
        z-index: 1;
        &.ReactModal__Overlay--after-open {
          opacity: 1;
          transition: opacity
            ${closeTimeoutMS ? (closeTimeoutMS * 3) / 4 : timeouts.NO_DELAY}ms
        ${animationCurves.easeOutQuart} ${timeouts.NO_DELAY}ms;
        }
        &.ReactModal__Overlay--before-close {
          opacity: 0;
          transition: opacity
            ${closeTimeoutMS ? (closeTimeoutMS * 3) / 4 : timeouts.NO_DELAY}ms
            ${animationCurves.easeInQuart}
            ${closeTimeoutMS ? closeTimeoutMS / 4 : timeouts.NO_DELAY}ms;
        }
      }

      &.ReactModal__Content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex: 0 0 auto;
        position: relative;
        height: auto;
        max-height: calc(100% - ${sizes.gridUnits.times8});
        width: calc(100% - ${sizes.gridUnits.times8});
        max-width: ${maxWidth ? `${maxWidth}px` : containerWidths.xl};
        &:focus {
          outline: 0;
        }
        opacity: 0;
        transform: scale(0) translateY(50%);
        &.ReactModal__Content--after-open {
          opacity: 1;
          transform: scale(1) translateY(0);
          transition: opacity ${closeTimeoutMS ? closeTimeoutMS : timeouts.NO_DELAY}ms
        ${animationCurves.easeOutQuart} ${timeouts.NO_DELAY}ms,
            transform ${closeTimeoutMS ? closeTimeoutMS : timeouts.NO_DELAY}ms
              ${animationCurves.easeOutQuart} ${timeouts.NO_DELAY}ms;
        }
        &.ReactModal__Content--before-close {
          opacity: 1;
          transform: scale(0) translateY(-50%);
          transition: transform ${closeTimeoutMS ? closeTimeoutMS : timeouts.NO_DELAY}ms
            ${animationCurves.easeInQuart} ${timeouts.NO_DELAY}ms;
        }
        @media ${responsiveTargets.phone} {
          max-height: 100%;
          width: 100%;
        }
    `;
  }}
`;

export const StyledPaper = styled(Paper)`
  align-self: stretch;
  width: 100%;
  height: auto;
  max-height: 100%;
  min-height: calc(50vh + ${iconButtonRowHeight} + ${footerHeight});
  overflow: hidden;
  overflow-y: scroll;
  padding: 0;
  position: relative;
  && {
    margin: 0;
  }
  &:last-child {
    padding-bottom: 0;
  }
  @media ${responsiveTargets.phone} {
    max-height: calc(100vh - ${sizes.gridUnits.times2});
    max-width: calc(100vw - ${sizes.gridUnits.times2});
  }
`;

export const IconButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  flex: 0 0 auto;
  padding-top: ${sizes.gridUnits.times1};
  padding-right: calc(${phonePadding} - ${scrollbarGap});
  padding-bottom: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: ${scrollbarGap}; /* leave room for scrollbar to show under */
  background-color: ${colors.background.light.secondary};
  box-shadow: 0px 7px 4px -4px rgba(253, 253, 253, 0.9);
  z-index: 2;
  @media ${responsiveTargets.phone} {
    margin-top: 0;
    left: ${sizes.gridUnits.times1};
  }
  ${Button} {
    margin: 0;
    &:last-child {
      margin-right: -${sizes.gridUnits.times1};
    }
  }
  ${DetailPrimaryValue} {
    padding: 0;
  }
`;

interface Props2 {
  hasIconRow: boolean;
  hasFooter: boolean;
  scrollbarWidth: number;
}

// The Content panel fills the window and scrolls underneath fixed-position button rows above.
// They all have to size their padding in concert.
export const Content = styled.div<Props2>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 0 0 auto;
  position: relative;
  left: ${sizes.gridUnits.times2};
  right: 0;
  margin-right: 0;
  max-width: calc(100vw - ${sizes.gridUnits.times4});
  @media ${responsiveTargets.phone} {
    padding-top: calc(
      ${phonePadding} + ${(props) => (props.hasIconRow ? iconButtonRowHeight : "0px")} +
        ${fadePadding}
    );
    left: ${phonePadding};
    margin-right: calc(${phonePadding} + ${(props) => props.scrollbarWidth}px);
    padding-right: calc(${phonePadding} - ${(props) => props.scrollbarWidth}px);
    padding-bottom: calc(
      ${fadePadding} + ${(props) => (props.hasFooter ? footerTopPadding : "0px")} +
        ${(props) => (props.hasFooter ? footerHeight : "0px")} + ${phonePadding}
    );
  }
  @media ${responsiveTargets.tablet} {
    padding-top: calc(
      ${tabletPadding} + ${(props) => (props.hasIconRow ? iconButtonRowHeight : "0px")} +
        ${fadePadding}
    );
    left: ${tabletPadding};
    margin-right: calc(${tabletPadding} + ${(props) => props.scrollbarWidth}px);
    padding-right: calc(${tabletPadding} - ${(props) => props.scrollbarWidth}px);
    padding-bottom: calc(
      ${fadePadding} + ${(props) => (props.hasFooter ? footerTopPadding : "0px")} +
        ${(props) => (props.hasFooter ? footerHeight : "0px")} + ${tabletPadding}
    );
  }
  @media ${responsiveTargets.desktop} {
    padding-top: calc(
      ${desktopPadding} + ${(props) => (props.hasIconRow ? iconButtonRowHeight : "0px")} +
        ${fadePadding}
    );
    left: ${desktopPadding};
    margin-right: calc(${desktopPadding} + ${(props) => props.scrollbarWidth}px);
    padding-right: calc(${desktopPadding} - ${(props) => props.scrollbarWidth}px);
    padding-bottom: calc(
      ${fadePadding} + ${(props) => (props.hasFooter ? footerTopPadding : "0px")} +
        ${(props) => (props.hasFooter ? footerHeight : "0px")} + ${desktopPadding}
    );
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex: 0 0 auto;
  position: fixed;
  bottom: 0;
  left: 0;
  right: ${scrollbarGap}; /* leave room for scrollbar to show under */
  background-color: ${colors.background.light.secondary};
  box-shadow: 0px -7px 4px -4px rgba(253, 253, 253, 0.9);
  z-index: 2;
  @media ${responsiveTargets.phone} {
    left: ${sizes.gridUnits.times1};
    padding: ${phonePadding};
    padding-right: calc(${phonePadding} - ${scrollbarGap});
    padding-top: calc(${fadePadding} + ${(props) => (props.children ? footerTopPadding : 0)});
  }
  @media ${responsiveTargets.tablet} {
    padding: ${tabletPadding};
    padding-right: calc(${tabletPadding} - ${scrollbarGap});
    padding-top: calc(${fadePadding} + ${(props) => (props.children ? footerTopPadding : 0)});
  }
  @media ${responsiveTargets.desktop} {
    padding: ${desktopPadding};
    padding-right: calc(${desktopPadding} - ${scrollbarGap});
    padding-top: calc(${fadePadding} + ${(props) => (props.children ? footerTopPadding : 0)});
  }
`;

export const FormEl = styled.form`
  align-self: stretch;
  flex: 1 1 auto;
  position: relative;
`;
