import { noop } from "@suited/utils";
import { Box, Stack, HStack } from "@suited/components";
import { timeouts } from "suited/constants/interaction-constants";
import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";
import BasicDialogHeader from "suited/components/shared/layout/Dialogs/BasicDialogHeader";
import { ResponsiveDialogModal } from "suited/components/shared/feedback/ResponsiveDialogModal/ResponsiveDialogModal";
import SuitedCopySubheadline from "suited/components/shared/typography/SuitedCopySubheadline";

export const TimeLeftWarningModal = ({ isOpen, onCancel, closeAssessmentSection }) => {
  return (
    <ResponsiveDialogModal
      maxWidth={700}
      show={isOpen}
      onCancel={noop}
      closeDuration={timeouts.MODAL_DEFAULT_CLOSE_DURATION}
      iconButtonRow={<BasicDialogHeader onCancel={onCancel} />}
      shouldCloseOnEsc={false}
    >
      <Box width="85ch">
        <Stack space="xl">
          <Box>
            <SuitedCopySubheadline noMargin>Before You Move On</SuitedCopySubheadline>
          </Box>
          <Box>
            <Stack>
              <p>
                You still have time remaining in this section. You will not be able to re-take this
                section of the assessment once it's completed and you won't be able to go back and
                review your responses.
              </p>
              <p>Are you sure you want to continue?</p>
            </Stack>
          </Box>
          <Box>
            <HStack justify="flex-end" space="sm">
              <Box>
                <SuitedButton purpose="default" onClick={onCancel}>
                  Cancel
                </SuitedButton>
              </Box>
              <Box>
                <SuitedButton
                  purpose="primary"
                  data-analytics-id="eca-section-finish-before-timer"
                  onClick={closeAssessmentSection}
                >
                  Next Section
                </SuitedButton>
              </Box>
            </HStack>
          </Box>
        </Stack>
      </Box>
    </ResponsiveDialogModal>
  );
};
