import React from "react";

import { Box, Stack, Center } from "@suited/components";
import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";
import { timeouts } from "suited/constants/interaction-constants";
import AssessmentDialog from "suited/components/CandidateHome/Assessment/AssessmentDialog/AssessmentDialog";
import SuitedAssessmentComponent from ".";
import { Completed, Instructions } from "./content/PsychometricAssessment";
import { InstructionContent } from "./SuitedAssessmentLanding.style";
import { LegalCopy } from "../shared/typography/SuitedLegalCopy";
import { ASSESSMENT_COMPLETE_ALT_TEXT } from "../shared/img/constants";
import { StyledBackgroundImg } from "../shared/img/StyledBackgroundImg";

interface Props {
  completedApplicationSurvey: boolean;
}

interface State {
  showAssessment: boolean;
  redirectToNextStep: boolean;
  showTrackingPixels: boolean;
}

class SuitedAssessmentLanding extends React.Component<Props, State> {
  state = {
    showAssessment: false,
    redirectToNextStep: false,
    showTrackingPixels: false
  };

  handleClickBegin = () => {
    this.setState({
      showAssessment: true,
      showTrackingPixels: true
    });
  };

  render() {
    if (this.props.completedApplicationSurvey) {
      return (
        <Box overflow="auto">
          <Stack>
            <StyledBackgroundImg role="img" aria-label={ASSESSMENT_COMPLETE_ALT_TEXT} />
            <Center>
              <Box padding="xl">
                <Stack space="xl">
                  <Completed />
                </Stack>
              </Box>
            </Center>
          </Stack>
        </Box>
      );
    } else {
      return (
        <Box padding="xl" overflow="auto">
          <Center>
            <InstructionContent>
              <AssessmentDialog
                show={this.state.showAssessment}
                onCancel={() => this.setState({ showAssessment: false })}
                renderAssessment={SuitedAssessmentComponent}
              />
              <Stack space="xl">
                <Instructions />
                <Box>
                  <Stack>
                    <LegalCopy>
                      THIS ASSESSMENT IS INTELLECTUAL PROPERTY CREATED AND ADMINISTERED BY SUITED.
                      BY PROCEEDING TO TAKE THIS ASSESSMENT, YOU AGREE TO NOT DISCLOSE,
                      REDISTRIBUTE, OR REPRODUCE THE CONTENTS, IN WHOLE OR IN PART, WITHOUT THE
                      PRIOR EXPRESS WRITTEN CONSENT OF SUITED.
                    </LegalCopy>
                  </Stack>
                </Box>
                <Center>
                  <SuitedButton
                    purpose="primary"
                    onClick={this.handleClickBegin}
                    delay={timeouts.BUTTON_CLICK_ANIMATION_DURATION}
                    data-analytics-id="psych-assessment-start"
                  >
                    Begin
                  </SuitedButton>
                </Center>
              </Stack>
            </InstructionContent>
          </Center>
        </Box>
      );
    }
  }
}

export default SuitedAssessmentLanding;
