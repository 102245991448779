import { Route, Redirect, Switch } from "react-router-dom";
import { connectedRouterRedirect } from "redux-auth-wrapper/history4/redirect";
import styled from "styled-components";
import locationHelperBuilder from "redux-auth-wrapper/history4/locationHelper";
import UserRegistration from "suited/components/UserRegistration";
import UserRequestPasswordReset from "suited/components/PasswordReset/UserRequestPasswordReset";
import UserPasswordReset from "suited/components/PasswordReset/UserPasswordReset";
import UserLoginContainer, { stateKey as authStateKey } from "suited/components/Login";
import RegistrationThankYou from "suited/components/UserRegistration/RegistrationThankYou";
import EmailConfirmedContainer from "suited/components/EmailConfirmedContainer";
import EmailAlreadyConfirmedComponent from "suited/components/EmailAlreadyConfirmed";
import UserEmailChangeConfirmed from "suited/components/CandidateHome/UserSettings/UserEmailChange/UserEmailChangeConfirmed";
import UserEmailChangeError from "suited/components/CandidateHome/UserSettings/UserEmailChange/UserEmailChangeError";
import ScrollToTop from "suited/components/shared/ScrollToTop";
import { ChangeEmailErrorStates } from "suited/components/CandidateHome/UserSettings/common";
import AppBootstrap from "suited/components/AppBootstrap";

const View = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
  flex: 1 1 auto;
  &:empty {
    display: none;
  }
`;

// build the authentication handler / redirect HOC
const locationHelper = locationHelperBuilder({});
const userIsNotAuthenticated = connectedRouterRedirect({
  redirectPath: (state, ownProps) => {
    if (state[authStateKey].invitationCode !== "") {
      return `/staticinvitation?code=${state[authStateKey].invitationCode}`;
    }
    return locationHelper.getRedirectQueryParam(ownProps) || "/home";
  },
  allowRedirectBack: false,
  authenticatedSelector: (state) =>
    !state || !state[authStateKey] || !state[authStateKey].isAuthenticated,
  wrapperDisplayName: "UserIsNotAuthenticated"
});

// build the authentication handler / redirect HOC
const userIsAuthenticated = connectedRouterRedirect({
  redirectPath: (_, ownProps) => {
    if (ownProps.location.pathname === "/invitation") {
      return `/login?id=${new URLSearchParams(ownProps.location.search).get("id")}`;
    }
    return "/login";
  },
  authenticatedSelector: (state) =>
    state && state[authStateKey] && state[authStateKey].isAuthenticated,
  wrapperDisplayName: "UserIsAuthenticated"
});

const CandidateHomeComponent = userIsAuthenticated(AppBootstrap);
const UserLoginComponent = userIsNotAuthenticated(UserLoginContainer);
const EmailConfirmedComponent = userIsNotAuthenticated(EmailConfirmedContainer);
const RegistrationThankYouComponent = userIsNotAuthenticated(RegistrationThankYou);

const AuthRoutes = () => {
  return (
    <View>
      <ScrollToTop>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/home" />} />
          <Route path="/home" component={CandidateHomeComponent} />
          <Route exact path="/login" component={UserLoginComponent} />
          <Route exact path="/email-confirmed" component={EmailConfirmedComponent} />
          <Route
            exact
            path="/staticinvitation"
            render={() => <Redirect to="/home/connection-manager" />}
          />
          <Route exact path="/registration-thank-you" component={RegistrationThankYouComponent} />
          <Route exact path="/register" component={UserRegistration} />
          <Route exact path="/user-request-password-reset" component={UserRequestPasswordReset} />
          <Route exact path="/user-reset-password" component={UserPasswordReset} />
          <Route exact path="/email-already-confirmed" component={EmailAlreadyConfirmedComponent} />
          <Route exact path="/change-email-confirmed" component={UserEmailChangeConfirmed} />
          <Route
            exact
            path="/change-email-error"
            render={(props) => (
              <UserEmailChangeError {...props} error={ChangeEmailErrorStates.GENERIC} />
            )}
          />
          <Route
            exact
            path="/change-email-token-expired"
            render={(props) => (
              <UserEmailChangeError {...props} error={ChangeEmailErrorStates.TOKENEXPIRED} />
            )}
          />
          <Route
            exact
            path="/change-email-token-incorrect"
            render={(props) => (
              <UserEmailChangeError {...props} error={ChangeEmailErrorStates.TOKENINCORRECT} />
            )}
          />
          <Route
            exact
            path="/change-email-cancelled"
            render={(props) => (
              <UserEmailChangeError {...props} error={ChangeEmailErrorStates.CANCELLED} />
            )}
          />
        </Switch>
      </ScrollToTop>
    </View>
  );
};

export default AuthRoutes;
