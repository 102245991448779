import styled from "styled-components";

import { ErrorText } from "./shared/typography/SuitedInputErrorMessage";
import { SuitedShowPasswordInput } from "suited/components/shared/inputs/SuitedShowPasswordInput";

export const StyledSuitedShowPasswordInput = styled(SuitedShowPasswordInput)`
  ${ErrorText} {
    /* since this input is at the bottom of the layout, we can wrap the error message */
    white-space: normal;
  }
`;
