import { useState } from "react";
import { timeouts } from "suited/constants/interaction-constants";
import { useAppDispatch, useAppSelector } from "suited/util/hooks/reduxHooks";
import { getIsEmailChangeModalOpen } from "../userSettings.slice";
import { showEmailChangeModal } from "../userSettings.slice";
import BasicDialogHeader from "suited/components/shared/layout/Dialogs/BasicDialogHeader";
import { ResponsiveDialogModal } from "suited/components/shared/feedback/ResponsiveDialogModal/ResponsiveDialogModal";
import { ConfirmPasswordForm } from "../ConfirmPasswordForm/ConfirmPasswordForm";
import { EMAIL_CHANGE_VIEWS } from "./UserEmailChangeModal.constants";
import { ChangeEmailForm } from "../ChangeEmailForm/ChangeEmailForm";
import { ChangeEmailNotification } from "../ChangeEmailNotification/ChangeEmailNotification";

export const UserEmailChangeModal = () => {
  const isEmailChangeModalOpen = useAppSelector(getIsEmailChangeModalOpen);
  const dispatch = useAppDispatch();

  const [emailChangeView, setEmailChangeView] = useState(EMAIL_CHANGE_VIEWS.CONFIRM_PASSWORD);
  const closeAndResetView = () => {
    dispatch(showEmailChangeModal(false));

    // Prevent flash of content by waiting until animation
    // transition is complete and reset view back to confirm password.
    setTimeout(() => {
      setEmailChangeView(EMAIL_CHANGE_VIEWS.CONFIRM_PASSWORD);
    }, timeouts.MODAL_DEFAULT_CLOSE_DURATION + 100);
  };
  return (
    <ResponsiveDialogModal
      maxWidth={700}
      show={isEmailChangeModalOpen}
      onCancel={closeAndResetView}
      closeDuration={timeouts.MODAL_DEFAULT_CLOSE_DURATION}
      iconButtonRow={<BasicDialogHeader onCancel={closeAndResetView} />}
      shouldCloseOnEsc={false}
    >
      {(() => {
        switch (emailChangeView) {
          case EMAIL_CHANGE_VIEWS.CONFIRM_PASSWORD:
            return (
              <ConfirmPasswordForm
                onClose={closeAndResetView}
                setEmailChangeView={setEmailChangeView}
              />
            );
          case EMAIL_CHANGE_VIEWS.CHANGE_EMAIL:
            return (
              <ChangeEmailForm
                onClose={closeAndResetView}
                setEmailChangeView={setEmailChangeView}
              />
            );
          case EMAIL_CHANGE_VIEWS.NOTIFICATION:
            return <ChangeEmailNotification onClose={closeAndResetView} />;
        }
      })()}
    </ResponsiveDialogModal>
  );
};
