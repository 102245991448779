import gql from "graphql-tag";

export const UPDATE_USER_PASSWORD = gql`
  mutation UpdateUserPassword($password: String!) {
    UpdateUser(password: $password) {
      id
    }
  }
`;

export const UPDATE_USER_NAME = gql`
  mutation UpdateUserName($firstName: String!, $lastName: String!) {
    UpdateUser(firstName: $firstName, lastName: $lastName) {
      id
      firstName
      lastName
    }
  }
`;

export const UPDATE_USER_SETTINGS_VISIBILITY = gql`
  mutation UpdateUserSettingsVisibility($visibility: VisibilitySetting!) {
    UpdateUser(visibility: $visibility) {
      id
      settings {
        visibility
      }
    }
  }
`;

export const VERIFY_USER_PASSWORD = gql`
  mutation VerifyUserPassword($password: String) {
    VerifyUserPassword(password: $password) {
      verified
      lastVerification
    }
  }
`;

export const INITIATE_CHANGE_EMAIL = gql`
  mutation InitiateChangeEmail($email: String) {
    InitiateChangeEmail(email: $email) {
      newEmail
      emailStatus
      initiatedAt
      isChangeWindowOpen
    }
  }
`;

export const CANCEL_CHANGE_EMAIL = gql`
  mutation CancelChangeEmail {
    CancelChangeEmail {
      newEmail
      emailStatus
      initiatedAt
      isChangeWindowOpen
    }
  }
`;
