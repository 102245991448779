import React from "react";
import ReactGA from "react-ga";
import { useHistory } from "react-router-dom";
import { getUserId } from "./util/authUtil";

import { useInitialMountEffect } from "suited/util/hooks/useInitialMountEffect.hook";

const activeTokenUserId = getUserId() || undefined;

ReactGA.initialize([
  {
    trackingId: "UA-117967504-1", //Suited: Web App - Production
    gaOptions: {
      userId: activeTokenUserId
    }
  },
  {
    trackingId: "UA-117899685-1", //Suited: Website
    gaOptions: {
      name: "marketingTracker",
      allowLinker: true,
      userId: activeTokenUserId
    }
  }
]);

type Props = {
  children: React.ReactNode;
};

// Built based on this post: https://github.com/react-ga/react-ga/issues/122#issuecomment-309246808
// but adapted to be a function component.
const GAListener = (props: Props) => {
  const history = useHistory();

  const sendPageView = (location) => {
    // When using multiple trackers, the default tracker plus an array of named trackers will send:
    ReactGA.set({ page: location.pathname }, ["marketingTracker"]);
    ReactGA.pageview(location.pathname, ["marketingTracker"]);
  };

  useInitialMountEffect(() => {
    sendPageView(history.location);
    history.listen(sendPageView);
  });

  return <React.Fragment>{props.children}</React.Fragment>;
};

export default GAListener;
