// import other reducers here in order to combine into a single top-level reducer
import alerts, { stateKey as alertsStateKey } from "suited/components/Alerts/reducers";
import { authReducer, stateKey as authStateKey } from "suited/components/Login";
import registrationReducer, {
  stateKey as registrationStateKey
} from "suited/components/UserRegistration/reducers";
import passwordResetReducer, {
  stateKey as passwordResetStateKey
} from "suited/components/PasswordReset/reducers";
import {
  featureFlagReducer,
  FEATURE_FLAG_STATE_KEY
} from "suited/components/FeatureFlags/FeatureFlags.slice";
import { ecaReducer, ECA_STATE_KEY } from "suited/components/CandidateHome/eca/eca.slice";
import {
  userSettingsReducer,
  USER_SETTINGS_STATE_KEY
} from "./components/CandidateHome/UserSettings/userSettings.slice";
import {
  lateralReducer,
  LATERAL_STATE_KEY
} from "suited/components/CandidateHome/lateral/lateral.slice";

const rootReducer = {
  [alertsStateKey]: alerts,
  [authStateKey]: authReducer,
  [registrationStateKey]: registrationReducer,
  [passwordResetStateKey]: passwordResetReducer,
  [FEATURE_FLAG_STATE_KEY]: featureFlagReducer,
  [ECA_STATE_KEY]: ecaReducer,
  [USER_SETTINGS_STATE_KEY]: userSettingsReducer,
  [LATERAL_STATE_KEY]: lateralReducer
};

export default rootReducer;
