import { useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _get from "lodash/get";
import { useEffect, useRef } from "react";
import { Link, match, useHistory } from "react-router-dom";
import ReactToolTip from "react-tooltip";
import styled from "styled-components";

import { Box, Center, Flex, Stack } from "@suited/components";

import { UnstyledButton } from "suited/components/shared/buttons/UnstyledButton";
import { PhoneAndTablet } from "suited/components/shared/responsive/Responsive";
import { colors as redesignColors } from "suited/constants/colors";
import { routePaths } from "suited/constants/routePaths";
import { colors, sizes, typography } from "suited/constants/style-constants";
import { AccountTypes } from "suited/constants/user.constants";
import logoSrc from "suited/img/icon-white.svg";
import { isCampusUser, isProfileComplete } from "suited/util/userProfile";

import { GET_USER_BOOTSTRAP } from "../GraphQL/Queries";
import { TUserQueryData } from "../GraphQL/types";
import { assessmentStatusTypes } from "../eca/eca.constants";
import { AssessmentIds as ECAAssessmentIds } from "../eca/eca.constants";
import { GET_CANDIDATE_ASSESSMENTS } from "../eca/eca.queries";
import type { GetCandidateAssessmentsQuery } from "../eca/eca.types";
import { AssessmentIds } from "../lateral/lateral.constants";
import NavItem from "./NavItem";
import { NavLabel } from "./NavItem.style";
import {
  FeedbackIcon,
  FeedbackLabelCopy,
  FeedbackLabelHelperText,
  ImgContainer,
  StyledHeaderImg,
  StyledNavLink,
  StyledSuitedIconButton
} from "./NavigationSidebar.style";

type Props = {
  user: any;
  isSuitedAssessmentCompleted: boolean;
  isProfileComplete: boolean;
  match: match;
  onNavigate?: () => void;
  onClickFeedback?: () => void;
};

const ProfileCompleteBadge = styled.div`
  flex: 0 0 auto;
  position: sticky;
  bottom: ${sizes.gridUnits.times10};
  background-color: ${redesignColors.sunflower};
  text-align: center;
  font-family: ${typography.family.heading};
  padding: 1rem;
  border-top: 1px solid ${colors.tints.times3};
  border-bottom: 1px solid ${colors.shades.times4};
  font-weight: ${typography.weight.heading.medium};
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NavigationSidebar = (props: Props) => {
  const { user, isSuitedAssessmentCompleted, match, onNavigate = () => {} } = props;
  const history = useHistory();

  const { loading: isUserBootstrapLoading, data: getUserBootstrapData } =
    useQuery<TUserQueryData>(GET_USER_BOOTSTRAP);
  const {
    data: candidateAssessmentsData,
    loading: isCandidateAssessmentsDataLoading,
    error: candidateAssessmentError
  } = useQuery<GetCandidateAssessmentsQuery>(GET_CANDIDATE_ASSESSMENTS);
  const iconRef = useRef();

  useEffect(() => {
    if (iconRef.current) {
      ReactToolTip.show(iconRef.current);
    }
  }, [candidateAssessmentError]);

  const isLoading = isUserBootstrapLoading || isCandidateAssessmentsDataLoading;

  // routes
  const homeUrl = `${match.url}`;
  const educationUrl = `${match.url}/education`;
  const workPreferencesUrl = `${match.url}/work-preferences`;
  const demographicUrl = `${match.url}/demographic`;
  const suitedAssessmentUrl = `${match.url}/suited-assessment/personality`;
  const resumeUrl = `${match.url}/resume`;

  const candidateAssessments = candidateAssessmentsData?.GetCandidateAssessments || [];

  const isCandidateAssessmentComplete =
    candidateAssessments?.find((assessment) => assessment.id === ECAAssessmentIds.ECA_ASSESSMENT)
      ?.status === assessmentStatusTypes.COMPLETE;

  const isLateralLawAssessmentComplete =
    candidateAssessments?.find((assessment) => assessment.id === AssessmentIds.LATERAL_LAW)
      ?.status === assessmentStatusTypes.COMPLETE;

  // profile completion data
  const completionData = _get(user, "userProfileCompletion", {});
  const areUserWorkPreferencesCompleted = !!_get(completionData, "completedWorkPreferences");

  const timerMultiplier = getUserBootstrapData?.GetUser?.accommodations?.assessmentTimerMultiplier;
  const ecaNavItemLabel =
    "Cognitive Skills Assessment" +
    (timerMultiplier && timerMultiplier > 1 ? ` (${timerMultiplier}× Time)` : "");

  const accountType = getUserBootstrapData?.GetUser?.accountType;
  const profileComplete =
    isCampusUser(accountType) &&
    isProfileComplete({
      ...completionData,
      isCandidateAssessmentComplete,
      isPsychometricAssessmentComplete: isSuitedAssessmentCompleted
    });

  return (
    <Stack space="none" height="100%">
      <Link to={homeUrl} onClick={onNavigate}>
        <Box bg={colors.background.dark.primary}>
          <Flex justify="space-between">
            <ImgContainer>
              <Center>
                <StyledHeaderImg src={logoSrc} title="Suited" alt="suited logo" />
              </Center>
            </ImgContainer>
            <PhoneAndTablet>
              <Flex justify="center" align="center" alignSelf="center">
                <Box padding="md">
                  <StyledSuitedIconButton
                    iconName="times"
                    iconWeight="regular"
                    onClickButton={onNavigate}
                    tooltip="Close menu"
                    delay={0}
                  />
                </Box>
              </Flex>
            </PhoneAndTablet>
          </Flex>
        </Box>
      </Link>
      <Box height="100%" width="17rem" bg={colors.background.dark.primary}>
        <Stack space="none">
          <Box>
            <StyledNavLink
              to={educationUrl}
              onClick={onNavigate}
              className="link"
              activeClassName="selected"
              completed={completionData.completedSchools}
            >
              <NavItem
                isLoading={isLoading}
                completed={completionData.completedSchools}
                url={educationUrl}
                label="Education"
              />
            </StyledNavLink>
          </Box>
          <Box>
            <StyledNavLink
              to={workPreferencesUrl}
              onClick={onNavigate}
              className="link"
              activeClassName="selected"
              completed={areUserWorkPreferencesCompleted}
            >
              <NavItem
                isLoading={isLoading}
                completed={areUserWorkPreferencesCompleted}
                url={workPreferencesUrl}
                placeholderCount={2}
                label="Location Preferences"
              />
            </StyledNavLink>
          </Box>
          <Box>
            <StyledNavLink
              to={demographicUrl}
              onClick={onNavigate}
              className="link"
              activeClassName="selected"
              completed={completionData.completedPersonalInformation}
            >
              <NavItem
                isLoading={isLoading}
                completed={completionData.completedPersonalInformation}
                url={demographicUrl}
                placeholderCount={2}
                label="Demographic Information"
              />
            </StyledNavLink>
          </Box>
          <Box>
            <StyledNavLink
              to={suitedAssessmentUrl}
              onClick={onNavigate}
              className="link"
              activeClassName="selected"
              completed={isSuitedAssessmentCompleted}
            >
              <NavItem
                isLoading={isLoading}
                completed={isSuitedAssessmentCompleted}
                url={suitedAssessmentUrl}
                placeholderCount={2}
                label="Behavioral Assessment"
              />
            </StyledNavLink>
          </Box>
          {candidateAssessmentError ? (
            <Box>
              <Flex justify="space-between">
                <Box padding="lg">
                  <NavLabel style={{ color: typography.color.status.error }}>
                    Error loading assessments
                  </NavLabel>
                </Box>
                <Flex flex="0 0 72px" justify="center" align="center" alignSelf="stretch">
                  <Box>
                    {/*@ts-ignore */}
                    <div ref={iconRef} data-tip data-for="eca-error-tooltip" data-event="event">
                      <FontAwesomeIcon
                        size="lg"
                        icon={["fas", "exclamation-triangle"]}
                        style={{ color: typography.color.status.error }}
                      />
                    </div>

                    <Box>
                      {/*@ts-ignore */}
                      <ReactToolTip
                        clickable
                        id="eca-error-tooltip"
                        place="right"
                        scrollHide={false}
                        resizeHide={false}
                        backgroundColor={typography.color.status.error}
                        getContent={() => (
                          <Box width="45ch">
                            Try signing out and signing back in to the app. If the issue persists,
                            please reach out to support@wellsuited.com.
                          </Box>
                        )}
                      />
                    </Box>
                  </Box>
                </Flex>
              </Flex>
            </Box>
          ) : (
            <>
              {getUserBootstrapData?.GetUser?.accountType === AccountTypes.LATERAL ? (
                <Box data-testid="assessment-nav-item">
                  <StyledNavLink
                    to={routePaths.LATERAL_ASSESSMENT}
                    onClick={onNavigate}
                    className="link"
                    activeClassName="selected"
                    completed={isLateralLawAssessmentComplete}
                  >
                    <NavItem
                      isLoading={isLoading}
                      completed={isLateralLawAssessmentComplete}
                      placeholderCount={3}
                      label="Lateral Law Assessment"
                    />
                  </StyledNavLink>
                </Box>
              ) : (
                <Box data-testid="assessment-nav-item">
                  <StyledNavLink
                    to={routePaths.ECA_ASSESSMENT}
                    onClick={onNavigate}
                    className="link"
                    activeClassName="selected"
                    completed={isCandidateAssessmentComplete}
                  >
                    <NavItem
                      isLoading={isLoading}
                      completed={isCandidateAssessmentComplete}
                      placeholderCount={3}
                      label={ecaNavItemLabel}
                    />
                  </StyledNavLink>
                </Box>
              )}
            </>
          )}

          <Box>
            <StyledNavLink
              to={resumeUrl}
              onClick={onNavigate}
              className="link"
              activeClassName="selected"
              completed={completionData.completedResume}
            >
              <NavItem
                isLoading={isLoading}
                completed={completionData.completedResume}
                url={resumeUrl}
                label="Resume"
              />
            </StyledNavLink>
          </Box>
        </Stack>
      </Box>
      <Stack space="none">
        {profileComplete && (
          <div
            style={{
              color: redesignColors.white
            }}
          >
            <Box>
              <ProfileCompleteBadge>Profile Complete</ProfileCompleteBadge>
            </Box>
          </div>
        )}
        <UnstyledButton onClick={() => history.push(`${match.url}/${routePaths.FAQ}`)}>
          <Box bg={colors.color1.base}>
            <Flex justify="space-between">
              <Box padding="md">
                <FeedbackLabelCopy>
                  <div>Need Help?</div>
                </FeedbackLabelCopy>
                <FeedbackLabelHelperText>
                  <div>Please reach out.</div>
                </FeedbackLabelHelperText>
              </Box>
              <Flex flex="0 0 72px" justify="center" align="center" alignSelf="stretch">
                <FeedbackIcon size="2x" icon={["fal", "comments-alt"]} />
              </Flex>
            </Flex>
          </Box>
        </UnstyledButton>
      </Stack>
    </Stack>
  );
};

export default NavigationSidebar;
