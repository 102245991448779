import React from "react";
import { Transition } from "react-transition-group";

import {
  IconButtonRow,
  StyledModal,
  StyledPaper,
  Content,
  Footer,
  FormEl
} from "./SuitedDialogWindow.style";
import { ScrollbarSizeContext } from "../../../DetectScrollbarSize";
import { timeouts } from "../../../../constants/interaction-constants";

interface Props {
  show: boolean;
  /**
   * Handler for cancel triggered by ESC key or click on overlay/scrim.
   * Use the same handler as you send with any cancel button in buttonRow or actionRow.
   */
  onCancel: () => any;
  /** Fragment containing JSX for Icon buttons at the top of the window .
   *  It makes sense to also place a window title here.
   */
  iconButtonRow?: React.ReactNode;
  /** The scrollable content of the window. */
  children?: JSX.Element[] | JSX.Element;
  /** Fragment containing JSX for Buttons at the bottom of the window for Confirm and Cancel. */
  renderFooter?: React.ReactNode;
  closeDuration?: number;
  maxWidth?: number;

  shouldFocusAfterRender?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  /**
   * Note: By disabling the esc key from closing the modal you may introduce an accessibility issue.
   */
  shouldCloseOnEsc?: boolean;
  shouldReturnFocusAfterClose?: boolean;
  /** String indicating how the content container should be announced to screenreaders */
  contentLabel?: string;
  /* Function that will be run after the modal has closed. */
  onAfterClose?(): void;
}

const SuitedDialogWindow = (props: Props) => {
  const {
    show,
    onCancel,
    children,
    closeDuration = timeouts.MODAL_DEFAULT_CLOSE_DURATION,
    ...passthroughProps
  } = props;
  return (
    // We wrap the entire modal inside a transition with the same animation timing so that it mounts and unmounts on show/hide.
    // This means we don't have to fuss with manually clearing its state between uses
    <Transition mountOnEnter={true} unmountOnExit={true} in={show} timeout={closeDuration}>
      <StyledModal
        isOpen={show}
        onRequestClose={onCancel}
        closeTimeoutMS={closeDuration}
        maxWidth={props.maxWidth}
        {...passthroughProps}
      >
        <ScrollbarSizeContext.Consumer>
          {({ value }) => (
            <StyledPaper>
              <FormEl
                onSubmit={(event) => {
                  // suppress browser default submit, trigger type="submit" button handler
                  event.preventDefault();
                }}
              >
                <IconButtonRow>{props.iconButtonRow}</IconButtonRow>
                <Content
                  hasIconRow={props.iconButtonRow ? true : false}
                  hasFooter={props.renderFooter ? true : false}
                  scrollbarWidth={value.scrollbarWidth}
                >
                  {props.children}
                </Content>
                {props.renderFooter ? <Footer>{props.renderFooter}</Footer> : null}
              </FormEl>
            </StyledPaper>
          )}
        </ScrollbarSizeContext.Consumer>
      </StyledModal>
    </Transition>
  );
};

export default SuitedDialogWindow;
