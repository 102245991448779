import React, { useReducer, useState } from "react";
import _findIndex from "lodash/findIndex";
import { Redirect } from "react-router-dom";
import { FetchResult } from "@apollo/client";

import { Box, Flex } from "@suited/components";
import { QuestionSchema, Answers, Answer, Errors } from "./types";
import { TransitionState } from "./types";
import { ANIM_SPEED, ANIM_DELAY, initialState } from "./constants";
import Question from "./Question";
import {
  SuitedAssessmentContainer,
  SuitedAssessmentContent,
  SuitedAssessmentFooter,
  SuitedAssessmentFooterButton,
  SuitedAssessmentFooterContent,
  IconLogoImage,
  SuitedAssessmentFooterProgress,
  HeaderIcon,
  SuitedAssessmentHeaderButton,
  Percent,
  SuitedAssessmentProgressIndicator
} from "./SuitedAssessment.style";
import { SuitedButton } from "../shared/buttons/SuitedButton";
import { timeouts } from "suited/constants/interaction-constants";
import SuitedDialogMountWrapper from "suited/components/shared/layout/Dialogs/SuitedDialogMount";
import SuitedDialogConfirm from "suited/components/shared/layout/Dialogs/SuitedDialogConfirm";
import SuitedBasicConfirmFooter from "suited/components/shared/layout/Dialogs/SuitedBasicConfirmFooter";
import { useInitialMountEffect } from "suited/util/hooks";
import { reducer } from "./reducer";
import logoSrc from "suited/img/icon-white.svg";
import { confirmContent } from "./content/PsychometricAssessment";
import { colors } from "suited/constants/style-constants";

interface ISuitedAssessmentProps {
  questions: QuestionSchema[];
  answers: Answers;
  percentComplete: number;
  setAnswer: (id: string, value: any) => Promise<FetchResult<Answer>>;
  submit: () => Promise<Errors>;
  onClose: () => void;
}

const SuitedAssessment: React.FunctionComponent<ISuitedAssessmentProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showSubmitModal, setShowSubmitModal] = useState<boolean>(false);
  const [questionPanelDisabled, setQuestionPanelDisabled] = useState<boolean>(false);

  const firstUnanswered = _findIndex(props.questions, (question: any) => {
    return !props.answers.hasOwnProperty(question.id) && question.type !== "statement";
  });

  useInitialMountEffect(() => {
    dispatch({
      type: "currentQuestionIndex",
      index: firstUnanswered === -1 ? props.questions.length - 1 : firstUnanswered
    });
  });

  const prevQuestion = () => {
    if (state.currentQuestionIndex > 0) {
      const index = state.currentQuestionIndex - 1;
      dispatch({ type: "transition", transition: TransitionState.TO_RIGHT });
      setTimeout(() => {
        dispatch({
          type: "transitionWithIndexUpdate",
          transition: TransitionState.FROM_LEFT,
          index: index
        });
        setTimeout(() => {
          dispatch({ type: "transition", transition: TransitionState.NONE });
        }, ANIM_SPEED);
      }, ANIM_SPEED);
    }
  };

  const nextQuestion = () => {
    if (state.currentQuestionIndex < props.questions.length - 1) {
      const index = state.currentQuestionIndex + 1;
      dispatch({ type: "transition", transition: TransitionState.TO_LEFT });
      setTimeout(() => {
        dispatch({
          type: "transitionWithIndexUpdate",
          transition: TransitionState.FROM_RIGHT,
          index: index
        });
        setTimeout(() => {
          dispatch({ type: "transition", transition: TransitionState.NONE });
        }, ANIM_SPEED);
      }, ANIM_SPEED);
    }
  };

  const advanceToNext = () => {
    if (
      state.transition === TransitionState.NONE &&
      state.currentQuestionIndex !== props.questions.length - 1
    ) {
      dispatch({ type: "transition", transition: TransitionState.UPDATING });
      setTimeout(() => {
        nextQuestion();
      }, ANIM_DELAY);
    }
  };

  const setAnswer = (value: any) => {
    // temporarily disables question panel to stop accidental
    // input; applies to all but last question
    if (state.currentQuestionIndex !== props.questions.length - 1) {
      setQuestionPanelDisabled(true);
      setTimeout(() => {
        setQuestionPanelDisabled(false); // reenables question panel
      }, ANIM_DELAY * 1.5);
    }

    const question = props.questions[state.currentQuestionIndex];
    props.setAnswer(question.id, value);
  };

  const showConfirmSubmitModal = () => {
    setShowSubmitModal(true);
  };

  const cancelSubmit = () => {
    setShowSubmitModal(false);
  };

  const confirmSubmit = () => {
    submit();
    setShowSubmitModal(false);
  };

  const submit = async () => {
    const errors = await props.submit();
    if (Object.keys(errors).length > 0) {
      const firstIncorrect = props.questions.findIndex((question) => {
        return errors.hasOwnProperty(question.id);
      });
      dispatch({ type: "error", errors, index: firstIncorrect });
    } else {
      props.onClose();
    }
  };

  const close = () => {
    props.onClose();
  };

  const index = state.currentQuestionIndex;
  const question = props.questions[index];

  // Chances are if question is undefined, they have completed the survey.
  // So this will put them at the survey success screen.
  if (question === undefined) return <Redirect push to="/home/suited-assessment/personality" />;

  const answer = props.answers[question.id];
  const error = state.errors[question.id] || "";

  return (
    <React.Fragment>
      <SuitedAssessmentContainer>
        <SuitedDialogMountWrapper show={showSubmitModal}>
          <SuitedDialogConfirm
            show={showSubmitModal}
            title={confirmContent.title}
            message={<p>{confirmContent.message}</p>}
            renderFooter={
              <SuitedBasicConfirmFooter
                confirmText="Submit"
                onCancel={cancelSubmit}
                onConfirm={confirmSubmit}
              />
            }
            onCancel={cancelSubmit}
          />
        </SuitedDialogMountWrapper>
        <Box bg={colors.background.dark.backdrop}>
          <Flex justify="space-between">
            <HeaderIcon>
              <IconLogoImage src={logoSrc} title="Suited" alt="Suited logo" />
            </HeaderIcon>
            <Flex align="center" alignSelf="stretch">
              <Box>
                <SuitedAssessmentHeaderButton
                  iconName="times"
                  onClickButton={close}
                  tooltip="Save and Close"
                  delay={timeouts.BUTTON_CLICK_ANIMATION_DURATION}
                />
              </Box>
            </Flex>
          </Flex>
        </Box>
        <SuitedAssessmentContent>
          <Question
            schema={question}
            answer={answer}
            error={error}
            transition={state.transition}
            setAnswer={setAnswer}
            advanceToNext={advanceToNext}
            disabled={questionPanelDisabled}
          />
        </SuitedAssessmentContent>

        {props.percentComplete >= 100 ? (
          <div style={{ bottom: 0, display: "absolute", padding: "1rem 0" }}>
            <SuitedAssessmentFooterContent>
              <SuitedButton purpose="primary" onClick={showConfirmSubmitModal}>
                Submit
              </SuitedButton>
            </SuitedAssessmentFooterContent>
          </div>
        ) : null}

        <SuitedAssessmentProgressIndicator
          value={props.percentComplete}
          timeout={0}
          transitionDuration={0}
          changeDuration={0}
        />
        <SuitedAssessmentFooter>
          <SuitedAssessmentFooterButton
            iconName="angle-left"
            iconWeight="solid"
            tooltip="Previous Question"
            disabled={state.transition !== TransitionState.NONE || state.currentQuestionIndex === 0}
            onClickButton={prevQuestion}
          />

          <SuitedAssessmentFooterContent>
            <SuitedAssessmentFooterProgress>
              <Percent>{Math.round(props.percentComplete)}%</Percent>
              Completed
            </SuitedAssessmentFooterProgress>
          </SuitedAssessmentFooterContent>

          <SuitedAssessmentFooterButton
            iconName="angle-right"
            iconWeight="solid"
            tooltip="Next Question"
            disabled={
              state.transition !== TransitionState.NONE ||
              !answer ||
              state.currentQuestionIndex === props.questions.length - 1
            }
            onClickButton={nextQuestion}
          />
        </SuitedAssessmentFooter>
      </SuitedAssessmentContainer>
    </React.Fragment>
  );
};

export default SuitedAssessment;
