import { createSlice } from "@reduxjs/toolkit";

import type { RootState } from "../../../configureStore";

export const USER_SETTINGS_STATE_KEY = "userSettings";

const userSettingsSlice = createSlice({
  name: USER_SETTINGS_STATE_KEY,
  initialState: {
    currentEmail: "",
    isEmailChangeModalOpen: false
  },
  reducers: {
    setCurrentEmail: (state, action) => {
      state.currentEmail = action.payload;
    },
    showEmailChangeModal: (state, action) => {
      state.isEmailChangeModalOpen = action.payload;
    }
  }
});

// Actions
export const { setCurrentEmail, showEmailChangeModal } = userSettingsSlice.actions;

// Selectors
export const getCurrentEmail = (state: RootState) => state.userSettings.currentEmail;
export const getIsEmailChangeModalOpen = (state: RootState) =>
  state.userSettings.isEmailChangeModalOpen;

// Reducer
export const userSettingsReducer = userSettingsSlice.reducer;
