import { HttpResponse, graphql } from "msw";

import { AccountTypes } from "suited/constants/user.constants";

import { INDUSTRY_CONTEXTS } from "../CandidateHome/Education/education.constants";

export const appBootstrapMockHandlers = [
  // Empty stub in order to run unit tests with msw for components like the
  // nav sidebar
  graphql.query("GetAssessments", () => {
    return HttpResponse.json({
      data: { GetAssessments: [] }
    });
  }),

  graphql.query("GetUserBootstrap", () => {
    return HttpResponse.json({
      data: {
        GetUserSurveyAnswers: null,
        GetUser: {
          id: "fd334c1f-f8d8-4347-85e8-cb410744c94c",
          firstName: "Test",
          lastName: "User",
          email: "testuser+test-school@wellsuited.com",
          accountType: AccountTypes.CAMPUS,
          userProfileCompletion: {
            completedSchools: true,
            completedPersonalInformation: false,
            completedResume: false,
            __typename: "UserProfileCompletionData"
          },
          settings: {
            visibility: "public",
            __typename: "UserSettings"
          },
          industryContext: {
            id: INDUSTRY_CONTEXTS.FINANCE,
            name: INDUSTRY_CONTEXTS.FINANCE,
            __typename: "IndustryContext"
          },
          accommodations: {
            assessmentTimerMultiplier: 1.0
          },
          __typename: "User"
        },
        GetAssessments: []
      }
    });
  })
];
