import React, { useEffect } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useForm, useWatch } from "react-hook-form";
import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";

import { Box, Stack, HStack } from "@suited/components";
import { isEmptyObject } from "@suited/utils";
import { useAppDispatch } from "suited/util/hooks/reduxHooks";
import { setCurrentEmail } from "../userSettings.slice";
import { GET_USER_SETTINGS } from "../GraphQL/queries";
import SuitedCopySubheadline from "suited/components/shared/typography/SuitedCopySubheadline";
import { DEFAULT_REQUIRED_ERROR_MESSAGE } from "suited/constants/validation.constants";
import { INITIATE_CHANGE_EMAIL } from "../GraphQL/mutations";
import { isValidEmailBoolean } from "suited/util/isValidEmail";
import { EMAIL_CHANGE_VIEWS } from "../UserEmailChangeModal/UserEmailChangeModal.constants";
import { StyledTextInput } from "suited/components/shared/inputs/StyledTextInput/StyledTextInput";
import { InputErrorMessage } from "suited/components/shared/inputs/InputErrorMessage/InputErrorMessage";
import { FadeInWrapper } from "../userSettings.styles";

type Props = {
  onClose: () => void;
  setEmailChangeView: (emailChangeView: string) => void;
};

export const ChangeEmailForm = (props: Props) => {
  const { onClose, setEmailChangeView } = props;
  const [getUserSettings] = useLazyQuery(GET_USER_SETTINGS, { fetchPolicy: "network-only" });
  const dispatch = useAppDispatch();

  const [initiateChangeEmail] = useMutation(INITIATE_CHANGE_EMAIL);

  const {
    control,
    register,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    formState: { errors, isDirty }
  } = useForm<{ changeEmail: string; root?: any }>({
    mode: "all",
    defaultValues: {
      changeEmail: ""
    }
  });

  const watchedValues = useWatch({ control });

  useEffect(() => {
    clearErrors("changeEmail");
    clearErrors("root.serverError");
  }, [watchedValues, clearErrors]);

  const handleEmailSubmit = async (values: { changeEmail: string }) => {
    try {
      await initiateChangeEmail({ variables: { email: values.changeEmail } });

      await getUserSettings();
      dispatch(setCurrentEmail(values.changeEmail));
      setEmailChangeView(EMAIL_CHANGE_VIEWS.NOTIFICATION);
    } catch (error) {
      if (error instanceof Error) {
        setError("root.serverError", {
          message: error?.message
        });
      }
    }
  };

  const formHasErrors = !isEmptyObject(errors);
  const isDisabled = !isDirty || formHasErrors;

  const closeAndReset = () => {
    onClose();
    reset();
  };

  return (
    <FadeInWrapper>
      <form onSubmit={handleSubmit(handleEmailSubmit)}>
        <Stack space="lg" width="85ch">
          <Box>
            <SuitedCopySubheadline noMargin>2. Change Email</SuitedCopySubheadline>
          </Box>
          <Box>
            <Stack>
              <p>
                Please enter the new email address you would like to use to access your account.
              </p>
              <div>
                <StyledTextInput
                  style={{ width: "100%" }}
                  {...register("changeEmail", {
                    required: DEFAULT_REQUIRED_ERROR_MESSAGE,

                    validate: (value: string) => {
                      if (!value) {
                        return DEFAULT_REQUIRED_ERROR_MESSAGE;
                      }

                      if (!isValidEmailBoolean(value)) {
                        return "Please enter a valid email address";
                      }
                    }
                  })}
                  error={
                    errors?.changeEmail?.message || (errors?.root as any)?.serverError?.message
                  }
                  onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    // Need to prevent modal from closing when form is submitted
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleSubmit(handleEmailSubmit)();
                    }
                  }}
                />
                <InputErrorMessage
                  message={
                    errors?.changeEmail?.message || (errors?.root as any)?.serverError?.message
                  }
                />
              </div>
            </Stack>
          </Box>
          <Box>
            <HStack justify="flex-end" space="sm">
              <Box>
                <SuitedButton type="submit" purpose="default" onClick={closeAndReset}>
                  Cancel
                </SuitedButton>
              </Box>
              <Box>
                <SuitedButton formButton purpose="primary" disabled={isDisabled}>
                  Confirm
                </SuitedButton>
              </Box>
            </HStack>
          </Box>
        </Stack>
      </form>
    </FadeInWrapper>
  );
};
