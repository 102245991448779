import owasp from "owasp-password-strength-test";
import React from "react";

import { debounce } from "../constants/interaction-constants";
import { StyledSuitedShowPasswordInput } from "./PasswordFields.style";

// NOTE: Need to do this check for jest testing to work
if (owasp && typeof owasp.config === "function") {
  owasp.config({ allowPassphrases: false });
}

interface Props {
  overDark?: boolean;
  passwordFieldLabel?: string;
  onPasswordValid: (string) => void;
  onPasswordInvalid: () => void;
  autoComplete?: "new-password" | "current-password";
}

interface State {
  password: string;
  passwordStrengthResults: any;
  passwordErrorList: string[];
  touched: boolean;
}

const initialState: State = {
  password: "",
  passwordStrengthResults: {},
  passwordErrorList: [],
  touched: false
};

class PasswordFields extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }

  onChangePassword = (value: any) => {
    const password = value;
    const passwordResults = owasp.test(password);
    let passwordErrorList: string[] = [];
    if (passwordResults.errors) {
      passwordErrorList = [...passwordErrorList, ...passwordResults.errors];
    }
    this.setState({
      password,
      passwordStrengthResults: passwordResults,
      passwordErrorList
    });
    if (passwordResults.strong) this.props.onPasswordValid(password);
    else this.props.onPasswordInvalid();
  };

  handlePasswordFocus = () => {
    this.setState({ touched: true });
  };

  render() {
    return (
      <StyledSuitedShowPasswordInput
        name="password"
        value={this.state.password}
        onInputChange={this.onChangePassword}
        label={this.props.passwordFieldLabel || "Password"}
        autoComplete={this.props.autoComplete}
        required={true}
        invalid={
          this.state.touched &&
          !this.state.passwordStrengthResults.strong &&
          this.state.passwordErrorList.length > 0
        }
        error={
          (this.state.passwordErrorList && this.state.passwordErrorList[0]) ||
          "This field is required."
        }
        isStrongPassword={this.state.passwordStrengthResults.strong}
        debounceTime={debounce.typeAhead}
        overDark={this.props.overDark}
        onInputFocus={this.handlePasswordFocus}
      />
    );
  }
}

export default PasswordFields;
