import { createRoot } from "react-dom/client";
import Modal from "react-modal";
import "raf/polyfill";

import App from "./App";
import "./index.css";
import "react-loading-skeleton/dist/skeleton.css";
import { seedDB } from "./mocks/seedDB";
import { AppConfig } from "./config";
import { worker, workerConfigOptions } from "./mocks/browser";

if (AppConfig.IS_DEV && AppConfig.VITE_ENABLE_MSW_MOCK_API === "true") {
  // @ts-ignore
  worker.start(workerConfigOptions);
  seedDB();
}

// for use of react-modal for accessibility:
// http://reactcommunity.org/react-modal/accessibility/
// and if we need to remove it at some point, pass this prop to the modal: ariaHideApp={false}
Modal.setAppElement("#root");

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
