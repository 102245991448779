import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faLockAlt as falLockAlt,
  faCheckCircle as falCheckCircle,
  faClock as falClock,
  faRedo as falRedo,
  faHeart as falHeart,
  faUser as falUser,
  faClipboardCheck as falClipboardCheck,
  faLeaf as falLeaf,
  faExclamationTriangle as falExclamationTriangle,
  faCommentsAlt as falCommentsAlt,
  faCircleQuestion as falCircleQuestion,
  faMobile as falMobile,
  faClipboardList as falClipboardList,
  faUserCog as falUserCog,
  faEnvelope as falEnvelope,
  faEnvelopeOpen as falEnvelopeOpen,
  faSignOutAlt as falSignOutAlt,
  faCloudDownload as falCloudDownload,
  faEye as falEye,
  faEyeSlash as falEyeSlash,
  faCheck as falCheck,
  faTimes as falTimes,
  faCircle as falCircle,
  faCircleXmark as falCircleXmark,
  faXmarkLarge as falXmarkLarge,
  faArrowsFromLine as falArrowsFromLine,
  faMagnifyingGlass as falMagnifyingGlass,
  faChevronDown as falChevronDown
} from "@fortawesome/pro-light-svg-icons";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import {
  faLockAlt as fasLockAlt,
  faCheckCircle as fasCheckCircle,
  faQuestionCircle as fasQuestionCircle,
  faExclamationTriangle as fasExclamationTriangle,
  faCaretDown as fasCaretDown,
  faCaretRight as fasCaretRight,
  faAcorn as fasAcorn,
  faTimes as fasTimes
} from "@fortawesome/pro-solid-svg-icons";

library.add(
  faInfoCircle,
  falClock,
  falRedo,
  falHeart,
  falUser,
  falClipboardCheck,
  falLeaf,
  falLockAlt,
  falCheckCircle,
  falExclamationTriangle,
  fasLockAlt,
  fasCheckCircle,
  falCommentsAlt,
  falCircleQuestion,
  falMobile,
  falClipboardList,
  falUserCog,
  falEnvelope,
  falEnvelopeOpen,
  falCloudDownload,
  falSignOutAlt,
  fasQuestionCircle,
  fasExclamationTriangle,
  fasCaretDown,
  fasCaretRight,
  fasAcorn,
  falEye,
  falEyeSlash,
  falCheck,
  falTimes,
  fasTimes,
  falCircle,
  falCircleXmark,
  falXmarkLarge,
  falArrowsFromLine,
  falMagnifyingGlass,
  falChevronDown
);
